var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "mb-5 row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "wiatrolap"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "lazienka"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "biuro"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "pok1"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "pok2"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "pok3"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('floor-box', {
    attrs: {
      "param": "salon"
    }
  })], 1)]), _vm._m(1), _c('div', {
    staticClass: "row"
  }, [_c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Tryb pracy grzejników"
    }
  }, [_c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "pompgrzejniki",
      "autoload": true,
      "align": "center",
      "size": "sm"
    }
  })], 1), _c('AppBox', {
    staticClass: "col-xs-12 col-md-6 col-lg-4",
    attrs: {
      "header": "Tryb pracy podłogówki"
    }
  }, [_c('FunctionBtn', {
    attrs: {
      "driver": "piec",
      "func": "pomppodloga",
      "autoload": true,
      "align": "center",
      "size": "sm"
    }
  })], 1)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Ogrzewanie")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Inne")])]);

}]

export { render, staticRenderFns }