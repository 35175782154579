var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "box"
  }, [_c('div', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.name))]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('dir', {
    staticClass: "m-0 p-0 ml-4"
  }, [_c('b-dropdown', {
    attrs: {
      "variant": "link",
      "id": "ddown1"
    }
  }, [_c('template', {
    slot: "button-content"
  }, [_c('i', {
    staticClass: "fas fa-chart-bar"
  })]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showStats('count');
      }
    }
  }, [_vm._v("Przekaźnik")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.showStats('list');
      }
    }
  }, [_vm._v("Temperatura")])], 2)], 1), _c('div', {
    staticClass: "current-temp"
  }, [_vm._v(" " + _vm._s(_vm.current) + "° "), _c('i', {
    staticClass: "fas fa-fire",
    class: _vm.onClass
  })]), _c('div', {
    staticClass: "mr-4"
  }, [_c('a', {
    staticClass: "d-inline-block p-1",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        _vm.visibleSettings = !_vm.visibleSettings;
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-cog"
  })])])], 1), _vm.visibleSettings ? _c('div', {
    staticClass: "d-flex justify-content-center my-3"
  }, [_c('FunctionBtn', {
    attrs: {
      "driver": _vm.driver,
      "func": _vm.func,
      "autoload": true,
      "size": "md",
      "param": _vm.param,
      "separator": _vm.funcObj.separator,
      "step": _vm.funcObj.valueStep,
      "type": "floor"
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }