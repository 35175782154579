<template>
  <div class="container px-0">
    <header>
      <h4 class="text-center my-4">Ogrzewanie</h4>
    </header>

    <div class="mb-5 row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="wiatrolap" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="lazienka" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="biuro" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="pok1" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="pok2" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="pok3" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <floor-box param="salon" />
      </div>
    </div>

    <header>
      <h4 class="text-center my-4">Inne</h4>
    </header>
    <div class="row">
      <AppBox
        header="Tryb pracy grzejników"
        class="col-xs-12 col-md-6 col-lg-4"
      >
        <FunctionBtn
          driver="piec"
          func="pompgrzejniki"
          :autoload="true"
          align="center"
          size="sm"
        />
      </AppBox>

      <AppBox
        header="Tryb pracy podłogówki"
        class="col-xs-12 col-md-6 col-lg-4"
      >
        <FunctionBtn
          driver="piec"
          func="pomppodloga"
          :autoload="true"
          align="center"
          size="sm"
        />
      </AppBox>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FloorBox from '@/components/heating/FloorBox'

export default {
  name: 'Heating',

  components: {
    FloorBox
  }
}
</script>
