<template>
  <div class="box">
    <div class="header">{{ name }}</div>

    <div class="d-flex justify-content-between">
      <dir class="m-0 p-0 ml-4">
        <b-dropdown variant="link" id="ddown1" class="">
          <template slot="button-content">
            <i class="fas fa-chart-bar"></i>
          </template>
          <b-dropdown-item @click.prevent="showStats('count')"
            >Przekaźnik</b-dropdown-item
          >
          <b-dropdown-item @click.prevent="showStats('list')"
            >Temperatura</b-dropdown-item
          >
        </b-dropdown>
      </dir>
      <div class="current-temp">
        {{ current }}&deg;
        <i class="fas fa-fire" :class="onClass"></i>
      </div>
      <div class="mr-4">
        <a
          href="#"
          class="d-inline-block p-1"
          @click.prevent="visibleSettings = !visibleSettings"
        >
          <i class="fas fa-cog"></i>
        </a>
      </div>
    </div>

    <div class="d-flex justify-content-center my-3" v-if="visibleSettings">
      <FunctionBtn
        :driver="driver"
        :func="func"
        :autoload="true"
        size="md"
        :param="param"
        :separator="funcObj.separator"
        :step="funcObj.valueStep"
        type="floor"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FloorBox',
  props: {
    driver: { default: 'piec' },
    func: { default: 'podlogasetup' },
    param: {
      type: [String, Boolean],
      default: false
    }
  },

  data() {
    return {
      visibleSettings: false
    }
  },
  computed: {
    ...mapState(['config', 'driversData']),

    current() {
      if ('piec' in this.driversData) {
        return this.driversData.piec[this.funcObj.params[this.param].current]
      }
      return ''
    },

    onClass() {
      if ('piec' in this.driversData) {
        if (
          parseInt(
            this.driversData.piec[this.funcObj.params[this.param].switch]
          ) === 1
        ) {
          return 'text-warning'
        }
      }
      return ''
    },

    funcObj() {
      return this.config[this.driver].functions[this.func]
    },

    name() {
      if ('params' in this.funcObj) {
        if (typeof this.funcObj.params[this.param] === 'object') {
          return this.funcObj.params[this.param].name
        } else {
          return this.funcObj.params[this.param]
        }
      } else {
        return this.funcObj.name
      }
    }
  },
  methods: {
    showStats(type) {
      window.mBus.$emit('statModal', {
        key:
          type === 'count'
            ? this.funcObj.params[this.param].switch
            : this.funcObj.params[this.param].current,
        driver: this.driver,
        name: this.name,
        type: type
      })
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.current-temp {
  font-size: 1.4rem;
  i {
    font-size: 1.2rem;
  }
}
</style>
